import client from "./internal/httpClient";

export function userlist(data) {
    return client.post("/admin/user-list", data);
}
export function addUser(data) {
    return client.post("/admin/add-user", data);
}
export function updateUser(data) {
    return client.post("/admin/reset-pwd", data);
}
export function deleteUser(data) {
    return client.post("/admin/delete-user", data);
}
