<template>
  <!-- Authors Table Card -->
  <div>
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">用户管理</h5>
          </a-col>
          <a-col
            :span="24"
            :md="12"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <a-button
              type="primary"
              class="add-shop"
              @click="() => (modal2Visible = true)"
              size="small"
            >
              新增用户
            </a-button>
          </a-col>
        </a-row>
      </template>

      <a-table :columns="columns" :data-source="data" :pagination="false">
        <template slot="shop" slot-scope="shop">
          <div class="table-avatar-info">
            <a-avatar shape="square" :src="shop.avatar" />
            <div class="avatar-info">
              <h6>{{ shop.name }}</h6>
              <p>{{ shop.email }}</p>
            </div>
          </div>
        </template>
        <template slot="company" slot-scope="shop">
          <div class="author-info">
            <h6 class="m-0">{{ shop.company }}</h6>
          </div>
        </template>
        <template slot="website" slot-scope="website">
          <div class="author-info">
            <h6 class="m-0">{{ website }}</h6>
          </div>
        </template>
        <template slot="editBtn" slot-scope="row">
          <div style="white-space: pre">
            <a-button
              type="link"
              :data-id="row.id"
              class="btn-edit"
              @click="openEditUser(row)"
            >
              重置密码
            </a-button>
            <a-popconfirm
              title="你确定要回收这个店铺吗？删除后不可恢复！"
              ok-text="是的"
              cancel-text="取消"
              @confirm="deleteUser(row)"
            >
              <a-button type="link" :data-id="row.id" class="btn-delete">
                删除
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      v-model="modal2Visible"
      :title="modelTitle"
      cancelText="取消"
      okText="确认"
      centered
      @cancel="handleCancelModel"
      @ok="handleAddUser"
    >
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="昵称" prop="name">
          <a-input
            :disabled="modelType == 'edit'"
            v-model="form.name"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="name" label="邮箱" prop="email">
          <a-input
            :disabled="modelType == 'edit'"
            v-model="form.email"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="name" label="电话" prop="phone">
          <a-input
            :disabled="modelType == 'edit'"
            v-model="form.phone"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="name" label="密码" prop="password">
          <a-input
            v-model="form.password"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>

  <!-- / Authors Table Card -->
</template>

<script>
import { addUser, updateUser,deleteUser } from "@/api/user";
import { loginadmin } from "@/api/login";
import { setShopToken } from "@/utils/index";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    handelType: {
      type: Function,
      required: true,
    },
    getAllUsers: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.getAllShopNames();
  },
  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      authorsHeaderBtns: "all",
      modal2Visible: false,
      modelType: "add",
      shopNameList: [],
      modelTitle: "新增店铺",
      currentUserId: 0,
      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
          //   { validator: this.validateName, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    // Compute the data to display based on current page and page size
    displayData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.data.slice(startIndex, endIndex);
    },
  },
  methods: {
    validateName(rule, value, callback) {
      if (!/^[a-zA-Z]*$/.test(value)) {
        callback(new Error("请输入英文字母"));
      } else {
        callback();
      }
    },
    handleInputChange(value) {
      // 使用正则表达式检查输入值是否只包含英文字母
      if (!/^[a-zA-Z]*$/.test(value)) {
        // 如果输入值包含非英文字母字符，则移除非英文字母字符
        this.searchText = value.replace(/[^a-zA-Z]/g, "");
      }
    },
    handleCancelModel() {
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.password = "";
      this.setModal1Visible(false);
      this.modelType = "add";
    },
    setModal1Visible(visiable) {
      this.modal2Visible = visiable;
    },
    ChangeShopType(e) {
      this.authorsHeaderBtns = e.target.value;
      this.handelType(e.target.value);
      this.getAllUsers(this.authorsHeaderBtns);
    },
    deleteUser(e) {
      deleteUser({"id":parseInt(e.id)}).then((res) => {
        this.$message.success("删除成功！");
        this.getAllUsers(this.authorsHeaderBtns);
      });
    },
    openEditUser(row) {
      this.modelTitle = "修改用户";
      this.modelType = "edit";
      this.form.name = row.name;
      this.form.email = row.email;
      this.form.phone = row.phone;
      this.form.password = "";
      this.setModal1Visible(true);
      this.currentUserId = parseInt(row.id);
    },
    handleAddUser() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.modelType == "add") {
            addUser(this.form).then((res) => {
              this.$message.success("注册成功！");
            });
          } else {
            this.form.id = this.currentUserId;
            updateUser(this.form).then((res) => {
              this.$message.success("修改成功！");
            });
          }
          this.setModal1Visible(false);
          this.form.name = "";
          this.form.email = "";
          this.form.phone = "";
          this.form.password = "";
          // 调用父组件的方法刷新数据
          this.getAllUsers(this.authorsHeaderBtns);
        } else {
          this.$message.warning("请检查表单！");
        }
      });
    },
  },
};
</script>
