import client from "./internal/httpClient";

export function login(email, password) {
  return client.post("/admin/login", {
    email: email,
    password: password,
  });
}
export function loginadmin(name, origin_domain) {
  return client.post("/seller/admin", {
    name: name,
    origin_domain: origin_domain,
  });
}
export function logout() {
  return client.post("/api/v1/auth/logout", {});
}


